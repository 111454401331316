import React from "react"
import MailchimpSubscribe from "react-mailchimp-subscribe"

const CustomForm = ({
    status,
    message,
    onValidated,
    className,
    btnClassName,
    btnText,
    onlyEmail,
}) => {
    let email, name
    if (!onlyEmail || onlyEmail === null) {
        var submit = () =>
            email &&
            name &&
            email.value.indexOf("@") > -1 &&
            onValidated({
                EMAIL: email.value,
                NAME: name.value,
            })
    } else {
        var submit = () =>
            email &&
            email.value.indexOf("@") > -1 &&
            onValidated({
                EMAIL: email.value,
            })
    }
    return (
        <>
            <div className="space-y-3">
                {status === "sending" && <div>sending...</div>}
                {status === "error" && (
                    <div dangerouslySetInnerHTML={{ __html: message }} />
                )}
                {status === "success" && (
                    <div dangerouslySetInnerHTML={{ __html: message }} />
                )}
                <div className={`${className}`}>
                    <input
                        className="w-full px-5 py-2 text-black placeholder-gray-500 bg-gray-100 rounded-lg md:bg-white hover:outline-none hover:ring hover:ring-red-400 focus:outline-none"
                        ref={node => (email = node)}
                        type="email"
                        placeholder="Email"
                    />
                    {!onlyEmail ? (
                        <input
                            className="w-full px-5 py-2 text-black placeholder-gray-500 bg-gray-100 rounded-lg md:bg-white hover:outline-none hover:ring hover:ring-red-400 focus:outline-none"
                            ref={node => (name = node)}
                            type="text"
                            placeholder="First Name"
                        />
                    ) : (
                        ""
                    )}
                    <br className="hidden md:block" />
                </div>
                <button
                    className={`btn-red ${btnClassName} whitespace-nowrap w-full`}
                    onClick={submit}
                >
                    {btnText}
                </button>
            </div>
        </>
    )
}

function FooterPopup({ className, btnClassName, btnText, onlyEmail }) {
    return (
        <MailchimpSubscribe
            url="https://antsle.us16.list-manage.com/subscribe/post?u=67fb0a69c97ce889a53356aaa&amp;id=306b51b4e9"
            render={({ subscribe, status, message }) => (
                <CustomForm
                    onlyEmail={onlyEmail}
                    btnText={btnText}
                    btnClassName={btnClassName}
                    className={className}
                    status={status}
                    message={message}
                    onValidated={formData => subscribe(formData)}
                />
            )}
        />
    )
}

export default FooterPopup
