import React from "react"
import { Link } from "gatsby"

export default function Header() {
  return (
    <header className="container py-5">
      <Link to="/">
        <span className="sr-only">Antsle</span>
        <img
          className="w-auto h-8 sm:h-10"
          src="https://antsle.com/wp-content/uploads/2019/08/Antsle_logo_l.png"
          alt="Antsle Logo"
        />
      </Link>
    </header>
  )
}
