import React, { useState, useEffect, Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import Mailchimp from "./Mail/Mailchimp"
import nanoImg from "../images/NanoRaffle.png" // New img with raffle headline
import newImg from "../images/new2.png"
import { IoMdClose, IoMdInformationCircle } from "react-icons/io"

export default function WinPopup() {
    const [active, setActive] = useState(true)
    const [open, setOpen] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)

    // useEffect(() => {
    //     setTimeout(() => {
    //         if (window.innerWidth <= 425) {
    //             setModalOpen(true)
    //         } else setOpen(true)
    //     }, 10000)
    // }, [])
    useEffect(() => {
        setTimeout(() => {
            setOpen(true)
        }, 4000)
    }, [])

    function closeModal() {
        setModalOpen(false)
    }

    function openModal() {
        setModalOpen(true)
    }
    const monthNames = [ "Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const msPerHour = 60 * 60 * 1000;
    const msPerDay = 24 * msPerHour;
    const msPerWeek = 7 * msPerDay;
    const offsetDrawing = 5 * msPerDay + 22 * msPerHour;
    var nowMsUTC = Date.now();
    var lastDrawing = Math.floor((nowMsUTC - offsetDrawing) / msPerWeek) * msPerWeek + offsetDrawing;
    var d = new Date(lastDrawing + msPerWeek); // Next Drawing
    // TODO: Subtract 1 hour during DST, 2nd Sunday in March - 1st Sunday in November
    return (
        <>
            <div
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                className={`
                ${active ? "" : "hidden"}
                ${
                    open ? "" : "translate-y-[370px] md:translate-y-[370px]"
                } fixed bottom-0 z-10 w-11/12 md:w-auto border-2 h-[420px] md:h-[420px] transform border-b-0 transition-transform duration-500 -translate-x-1/2 bg-black bg-opacity-75 backdrop-blur rounded-t-2xl border-red left-1/2`}
            >
                <img
                    className="h-[340px] mx-auto translate-y-[-50%]"
                    src={nanoImg}
                    alt=""
                />
                <button
                    onClick={() => setActive(false)}
                    className="absolute p-2 rounded-xl top-1 right-1 hover:bg-white/10"
                >
                    <IoMdClose className="text-lg text-white " />
                </button>
                <div className="px-5 flex flex-col translate-y-[-260px] md:translate-y-[-260px] items-center justify-center text-white">
                    <div className="flex items-center space-x-3">
                        <img
                            src={newImg}
                            alt="new"
                            className="hidden w-auto h-16 md:block"
                        />
                        <p className="text-2xl text-center md:text-left">
                            Weekly Antsle Nano Raffle
                        </p>
                        <p
                            onClick={() => {
                                openModal()
                                setOpen(false)
                            }}
                            className="opacity-75 cursor-pointer hover:underline"
                        >
                            <IoMdInformationCircle className="text-3xl opacity-90 hover:opacity-100" />
                        </p>
                    </div>
                    <p>
                      Next drawing: Tuesday, {monthNames[d.getUTCMonth()]} {d.getUTCDate()}, 2pm PST
                    </p>
                    <Mailchimp
                        className="flex flex-col mt-10 space-y-3 md:space-y-0 md:space-x-3 md:flex-row"
                        btnText="Enter to Win"
                        btnClassName="!bg-red-500 hover:!bg-red-400 text-xl"
                    />
                </div>
            </div>
            <Transition appear show={modalOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto bg-black/20 backdrop-blur-lg"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl md:max-w-2xl rounded-2xl">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900 md:text-xl"
                                >
                                    Antsle Sweepstakes Terms and Conditions
                                </Dialog.Title>
                                <div className="mt-2 prose text-gray-500 md:prose-lg" style={{padding: '2em'}}>
                                    <p>
                                        All we store is your first name and
                                        email address.
                                    </p>
                                    <p>
                                        We keep your information private. We will
                                        never sell your data. No purchase
                                        needed. Whether you make purchase or not
                                        - your odds of winning remain
                                        unaffected. The prize is an antsle Nano
                                        private cloud server. The value is the
                                        current price listed on{" "}
                                        <a href="https://antsle.com">
                                            antsle.com
                                        </a>
                                        .
                                    </p>
                                    <p>
                                        If you want, you can upgrade your prize
                                        to a larger model. In that case, we will
                                        charge you for the difference in price.
                                        You can find the prices of upgrades on{" "}
                                        <a href="https://antsle.com">
                                            antsle.com
                                        </a>
                                        .
                                    </p>
                                    <p>
                                        We will ship your prize for free to your
                                        address within the US and Canada.
                                        Physical addresses only, we will not
                                        ship to PO boxes. You do not need to pay
                                        anything to participate. Drawings take
                                        place every Tuesday. The odds of winning
                                        are the same for each participant.
                                    </p>
                                    <p>
                                        We give away one prize every week,
                                        and each participant that entered from
                                        the day of the last drawing up until the
                                        day immediately before the day of the
                                        drawing has the same odds of winning.
                                        The actual odds of winning vary with the
                                        number of entrants. In a typical
                                        week, we expect the odds to be around
                                        the range of 1 in 500 to 1 in 5,000.
                                    </p>
                                </div>

                                <div className="mt-4">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white text-blue-900 bg-red-500 border border-transparent rounded-md hover:bg-red-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                                        onClick={closeModal}
                                    >
                                        Got it, thanks!
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
