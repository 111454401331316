import * as React from "react"
import PropTypes from "prop-types"
import WinPopup from "../WinPopup"
const Layout = ({ children }) => {
    return (
        <>
            <main
                id="root"
                className="bg-white dark:bg-black transition-colors duration-[400ms]"
            >
                {children}
                <WinPopup />
            </main>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
