import "./src/css/index.css"
import React from "react"
import Layout from "./src/components/Layout/Layout"
import Header from "./src/components/Layout/Header"
import Footer from "./src/components/Layout/Footer"

export function wrapPageElement({ element, props }) {
  return (
    <Layout {...props}>
      <Header />
      {element}
      <Footer />
    </Layout>
  )
}

export function wrapRootElement({ element }) {
  return <>{element}</>
}
